import styled, {css} from "styled-components";
import Button, {ButtonProps} from "@mui/material/Button";
import {ReactNode} from "react";

export const StyledRefsalLogo = styled.img`
  object-fit: contain;
  height: 85px;
  margin: 15px 0;
`;

export const StyledNavbarList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-left: auto;

  @media (max-width: 1279px) {
    padding-bottom: 1rem;
    margin-left: unset;

    li:first-child {
      padding-left: 0;
    }
  }

  @media (max-width: 699px) {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`;

export const StyledNavbarListItem = styled.li`
  padding-left: 1rem;

  @media (max-width: 699px) {
    padding-left: 0;
  }
`;

interface StyledLoginButtonProps extends ButtonProps {
    to?: string;
    children?: ReactNode;
}

export const StyledLoginButton = styled(Button)<StyledLoginButtonProps>``;

export const StyledHeaderSection = styled.section`
  min-height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 1030px) {
    padding: 3rem 0;
    min-height: unset;
    display: block;
  }
`;

export const StyledHeaderImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  object-position: right;
`;

export const StyledHeaderHolder = styled.div`
  width: 100%;
`;

export const StyledHeaderTitle = styled.h1`
  color: #ffffff;
  font-size: 3rem;
  font-weight: lighter;
  margin-bottom: 1rem;
`;

export const StyledHeaderSubtitle = styled.p`
  color: #ffffff;
  font-size: 1rem;
  max-width: 480px;
  line-height: 1.75rem;
`;

export const StyledHeaderButtons = styled.div`
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1030px) {
    position: relative;
    bottom: unset;
    margin-top: 3rem;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.625rem;

  @media (max-width: 1030px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 575px) {
    width: 100%;
  }

  .button-icon {
    margin-right: 0.5rem;
  }
`;

interface StyledDepartmentsProps {
    isForDepartments?: boolean;
}

const getSsForDepartmentsStyles = () =>
    css`
    @media (max-width: 959px) {
      padding-left: 0;
    }
  `;

interface StyledSectionTitleProps extends StyledDepartmentsProps {
    textAlign: "center" | "left";
}

export const StyledSectionTitle = styled.h2<StyledSectionTitleProps>`
  color: rgba(0, 0, 0, 0.54);
  font-size: 2.125rem;
  padding: 1.5rem;
  ${({isForDepartments}) =>
    isForDepartments &&
    css`
      padding-top: 0;
    `}
  font-weight: lighter;
  text-align: ${({textAlign}) => textAlign};
  margin-top: 0;

  ${({isForDepartments}) => isForDepartments && getSsForDepartmentsStyles()}
`;

export const StyledInfoSectionDescription = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
`;

export const StyledInfoSectionSubtitle = styled.h3`
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5rem;
  padding: 1.5rem;
  font-weight: lighter;
  padding-top: unset;
  padding-bottom: unset;

  @media (max-width: 959px) {
    padding-left: 0;
  }
`;

export const StyledDepartmentDescription = styled.p`
  color: rgba(0, 0, 0, 0.54);
  padding: 0.5rem 1.5rem;

  @media (max-width: 959px) {
    padding-left: 0;
  }
`;

export const StyledSplitButtonWrapper = styled.div`
  padding: 0.5rem 1.5rem;
  @media (max-width: 959px) {
    padding-left: 0;
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const StyledFooter = styled.footer`
  position: relative;
  padding-top: 2rem;
`;

export const StyledFooterDescription = styled.p`
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 1.5rem;
  text-align: center;
`;

export const StyledFooterSection = styled.section`
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const StyledFooterTitle = styled.span`
  position: relative;
  display: block;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 0.5rem;
`;

export const StyledFooterMenu = styled.div`
  display: inline-block;
`;

export const StyledFooterMenuLink = styled.a`
  font-size: 0.875rem;
  padding-right: 0.85rem;

  text-decoration: none;
  color: inherit;
  @media (max-width: 630px) {
    display: block;
    padding-top: 1rem;
    width: 100%;
  }
`;

export const StyledFooterImg = styled.img`
  height: 100px;
  width: 100%;
  object-fit: contain;

  @media (max-width: 1279px) {
    height: 60px;
  }

  @media (max-width: 959px) {
    height: 50px;
  }
`;

export const StyledFooterImgSpan = styled.span`
  position: absolute;
  font-size: 0.6rem;

  @media (max-width: 1279px) {
    position: relative;
    display: block;
    padding-bottom: 0.5rem;
  }

  @media (max-width: 599px) {
    text-align: center;
  }
`;

export const StyledContactWayTitle = styled.span``;

export const StyledFooterAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
