import VerticalLinearStepper from "core/views/Home/components/Stepper";
import abm from "./images/abm.png";
import umw from "./images/umw.png";
import bio50 from "./images/50bio.png";
import neoVinci from "./images/neovinci.png";
import headerImg from "./images/header.jpg";
import imprv from './images/logo_improve@1X.png'
import {
    Box,
    Button,
    Container,
    Grid,
} from "@mui/material";
import { ROUTES_CORE } from "commons/constants/paths";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import {
    StyledHeaderSection,
    StyledHeaderImg,
    StyledHeaderHolder,
    StyledHeaderTitle,
    StyledHeaderSubtitle,
    StyledHeaderButtons,
    StyledButton,
    StyledSectionTitle,
    StyledInfoSectionDescription,
    StyledAnchor,
    StyledRefsalLogo,
    StyledNavbarList,
    StyledNavbarListItem,
    StyledLoginButton,
    StyledFooter,
    StyledFooterDescription,
    StyledFooterSection,
    StyledFooterTitle,
    StyledFooterMenu,
    StyledFooterMenuLink,
    StyledFooterImg,
    StyledFooterImgSpan,
    StyledContactWayTitle,
    StyledFooterAnchor,
} from "./Home.styled";
import MedicalCenter from "../components/MedicalCenter";
import { Link } from "react-router-dom";
import { PROJECT_EMAIL, PROJECT_PHONE } from "../../../../commons/constants/env";

function Home() {
    return (
        <div>
            <header>
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid container item lg={3} xs={12} spacing={0}>
                            <StyledRefsalLogo src={imprv} alt="Projekt Refsal logo"/>
                        </Grid>
                        <Grid container item lg={9} xs={12} spacing={0}>
                            <StyledNavbarList>
                                <StyledNavbarListItem>
                                    <Button size="medium">Primary objective(s)</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button size="medium">Trial site(s)</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button size="medium">CONTACT</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <StyledLoginButton
                                        variant="contained"
                                        color="primary"
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        SIGN IN
                                    </StyledLoginButton>
                                </StyledNavbarListItem>
                            </StyledNavbarList>
                        </Grid>
                    </Grid>
                </Container>
            </header>

            <StyledHeaderSection>
                <StyledHeaderImg src={headerImg} alt="Background img"/>
                <StyledHeaderHolder>
                    <Container maxWidth="lg">
                        <StyledHeaderTitle>
                            <b>IMPROVE-MC</b>
                        </StyledHeaderTitle>
                        <StyledHeaderSubtitle>
                            A multicenter, randomized, double-blind, placebo-controlled study
                            to evaluate the efficacy of immunosuppression in biopsy-proven virus negative myocarditis or
                            inflammatory cardiomyopathy (IMPROVE-MC).
                        </StyledHeaderSubtitle>
                    </Container>
                </StyledHeaderHolder>
                <StyledHeaderButtons>
                    <StyledButton size="large" color="secondary" variant="contained">
                        <DescriptionOutlinedIcon className="button-icon"/>
                        TRIAL DESCRIPTION
                    </StyledButton>
                    <StyledButton size="large" color="secondary" variant="contained">
                        <AssignmentOutlinedIcon className="button-icon"/>
                        TRAIL PROTOCOL
                    </StyledButton>
                    <StyledButton size="large" color="secondary" variant="contained">
                        <SubjectOutlinedIcon className="button-icon"/>
                        INVESTIGATOR'S BROCHURE
                    </StyledButton>
                    <StyledButton size="large" color="secondary" variant="contained">
                        <PlayCircleOutlineOutlinedIcon className="button-icon"/>
                        eCFR INSTRUCTION
                    </StyledButton>
                </StyledHeaderButtons>
            </StyledHeaderSection>
            <section>
                <Container maxWidth="lg">
                    <StyledSectionTitle textAlign="center">
                        Primary objective(s)
                    </StyledSectionTitle>
                    <StyledInfoSectionDescription>
                        The objective of this trial is to assess the efficacy and safety of 12 – month treatment with
                        prednisone and azathioprine
                        comparing to placebo on top of guideline-recommended medical therapy in patients with
                        biopsy-proven virus negative myocarditis
                        or inflammatory cardiomyopathy and reduced ejection fraction (LVEF ≤ 45%). The study will also
                        assess persistence
                        of the treatment effects after 12 months.
                    </StyledInfoSectionDescription>
                    <VerticalLinearStepper/>
                    <MedicalCenter />
                </Container>
            </section>
            <StyledFooter>
                <Container maxWidth="lg">
                    <StyledFooterSection>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <StyledFooterTitle>Menu:</StyledFooterTitle>
                                <StyledFooterMenu>
                                    <StyledFooterMenuLink href="#">
                                        PRIMARY OBJECTIVE(S)
                                    </StyledFooterMenuLink>
                                    <StyledFooterMenuLink href="#">
                                        TRIAL SITE(S)
                                    </StyledFooterMenuLink>
                                </StyledFooterMenu>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Contact:</StyledFooterTitle>
                                <StyledContactWayTitle>e-mail: </StyledContactWayTitle>
                                <StyledAnchor href={`mailto:${PROJECT_EMAIL}`}>
                                    {PROJECT_EMAIL}
                                </StyledAnchor>
                                <br/>
                                <StyledContactWayTitle>phone: </StyledContactWayTitle>

                                <StyledFooterAnchor href={`tel:${PROJECT_PHONE}`}>
                                    {PROJECT_PHONE}
                                </StyledFooterAnchor>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Sign in to eCRF:</StyledFooterTitle>
                                <Box display="flex" justifyContent="flex-end">
                                    <StyledLoginButton
                                        color="primary"
                                        variant="contained"
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        SIGN IN
                                    </StyledLoginButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                    <StyledFooterSection>
                        <StyledFooterDescription>
                            Financial support: Polish Medical Research Agency (Grant number: 2019/ABM/01/00047-00)
                        </StyledFooterDescription>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <StyledFooterImg
                                    src={imprv}
                                    alt="Projekt Refsal logo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={abm} alt="ABM logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={umw} alt="UMW logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>
                                    Contract Research Organization (CRO):
                                </StyledFooterImgSpan>
                                <StyledFooterImg src={bio50} alt="50Bio logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>
                                    Technical organizator:
                                </StyledFooterImgSpan>
                                <StyledFooterImg src={neoVinci} alt="Neo-Vinci logo"/>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                </Container>
            </StyledFooter>
        </div>
    );
}

export default Home;
